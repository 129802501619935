<template>
  <nuxt />
</template>

<script>
import { mapActions } from 'vuex';
import { addAdmitadScript, addDeduplicationScript } from '~/utils/addAdmitadScript';
import { addAdvCakePage } from '~/utils/advCake';
import { saveUtm } from '~/utils/routeUtm';

export default {
  name: 'EmptyLayout',

  head() {
    return {
      link: [
        { rel: 'preconnect', href: 'https://fonts.gstatic.com', crossorigin: true },
        { rel: 'preconnect', href: 'https://fonts.googleapis.com' },
        {
          rel: 'stylesheet',
          href: 'https://fonts.googleapis.com/css2?family=Onest:wght@100..900&display=swap',
        },
      ],
    };
  },

  watch: {
    $route() {
      this.$yaMetrika.hit(this.$route.fullPath);
    },
  },

  mounted() {
    addDeduplicationScript();
    addAdmitadScript();

    saveUtm(this.$route);

    addAdvCakePage(this.$route);

    window.addEventListener('resize', this.setWindowSize);
    this.setWindowSize({ target: window });
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setWindowSize);
  },

  methods: {
    ...mapActions({
      setWindowSize: 'setWindowSize',
    }),
  },
};
</script>

<style scoped lang="scss">

// артефакты от старых страниц
::v-deep section {
  margin-bottom: 0;

  h2,
  h3 {
    margin: 0;
  }
}

</style>
