<template>
  <main class="v2-layout">
    <common-modals />
    <page-bottom-banner />
    <scroll-top-button />

    <app-header-old v-if="oldHeaderPage" />
    <app-header v-else />

    <app-header-modal
      v-if="currentModal"
      @signin="purchaseSignIn = true"
    />
    <purchase-auth-modal
      v-if="purchaseSignIn"
      @close="purchaseSignIn = false"
    />
    <app-header-modal-old v-if="showHeaderModal" />

    <webinar-registration-modal v-if="showWebinarRegistrationModal" />

    <nuxt class="page" />

    <synergy-friends v-if="!onlineSchoolOtherPage" />
    <app-footer />

    <notifications position="top right" />
  </main>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { addAdmitadScript, addDeduplicationScript } from '~/utils/addAdmitadScript';
import { addAdvCakePage } from '~/utils/advCake';
import { saveUtm } from '~/utils/routeUtm';

import PageBottomBanner from '~/components/school/PageBottomBanner.vue';
import AppHeader from '~/components/v2/common/AppHeader/AppHeader.vue';
import AppHeaderModalOld from '~/components/v2/common/AppHeaderModalOld.vue';
import AppFooter from '~/components/v2/common/AppFooter.vue';
import ScrollTopButton from '~/components/v2/common/ScrollTopButton.vue';
import CommonModals from '~/components/v2/modals/CommonModals.vue';
import AppHeaderOld from '~/components/v2/common/AppHeaderOld.vue';
import AppHeaderModal from '~/components/v2/common/AppHeader/AppHeaderModal.vue';
import PurchaseAuthModal from '~/components/v2/purchase-auth/PurchaseAuthModal.vue';
import SynergyFriends from '~/components/v2/common/SynergyFriends.vue';
import WebinarRegistrationModal from '~/components/v2/modals/WebinarRegistrationModal.vue';

export default {
  name: 'DefaultLayoutV2',
  components: {
    SynergyFriends,
    PurchaseAuthModal,
    AppHeaderModal,
    AppHeaderOld,
    CommonModals,
    PageBottomBanner,
    ScrollTopButton,
    AppFooter,
    AppHeader,
    AppHeaderModalOld,
    WebinarRegistrationModal,
  },

  data: () => ({
    purchaseSignIn: false,
  }),

  head() {
    return {
      link: [
        { rel: 'preconnect', href: 'https://fonts.gstatic.com', crossorigin: true },
        { rel: 'preconnect', href: 'https://fonts.googleapis.com' },
        {
          rel: 'stylesheet',
          href: 'https://fonts.googleapis.com/css2?family=Onest:wght@100..900&display=swap',
        },
      ],
    };
  },
  computed: {
    ...mapGetters('headerModals', ['showHeaderModal']),
    ...mapGetters('appHeaderModals', ['currentModal']),
    ...mapGetters('modals', ['showWebinarRegistrationModal']),

    oldHeaderPage() {
      const oldRouteNames = ['online-school-partners'];
      return oldRouteNames.includes(this.$route.name);
    },
    onlineSchoolOtherPage() {
      return [
        'online-school-zaochnoe-obuchenie',
        'online-school-attestacii-i-prikreplenie',
        'online-school-vechernee-obuchenie',
        'online-school-semeinoe-obuchenie',
        'online-school-semeinoe-obuchenie-ceny',
      ].includes(this.$route.name);
    },
  },
  watch: {
    $route() {
      this.$yaMetrika.hit(this.$route.fullPath);
    },
  },

  mounted() {
    addDeduplicationScript();
    addAdmitadScript();

    addAdvCakePage(this.$route);

    saveUtm(this.$route);

    window.addEventListener('resize', this.setWindowSize);
    this.setWindowSize({ target: window });
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setWindowSize);
  },

  methods: {
    ...mapActions(['setWindowSize']),
  },
};
</script>

<style scoped lang="scss">
.v2-layout {
  overflow: hidden;
  background-color: #ECEDF0;

  ::v-deep .page-banner ~ .header {
    @include media-up(tablet) {
      top: 100px;
    }
  }
  ::v-deep .common-modals ~ .page-banner {
    display: none;
  }

  ::v-deep .common-modals ~ .header {
    top: 0 !important;
  }

  ::v-deep .page-bottom-banner {
    & ~ .stb-button {
      bottom: calc(var(--scale) * 120);
    }

    & ~ .header__wrapper header,
    & ~ .header {
      @include media-down($size-tablet) {
        top: calc(var(--scale) * 50);

        .header__inner {
          top: 0;
        }
      }
    }

    & ~ .page {
      @include media-down($size-tablet) {
        margin-top: calc(var(--scale) * 60);
      }

      .repetitor-catalog__mob-btn,
      .programmirovanie-catalog__mob-btn {
        top: calc(var(--scale) * 130);
      }
    }

    & ~ .footer {
      padding-bottom: calc(var(--scale) * 100);
    }
  }

  .footer {
    @include media-down($size-tablet) {
      padding-bottom: calc(var(--scale) * 80) !important;
    }
  }

  &:has(.external-adult-bottom-banner) {
    @include media-down(tablet) {
      .header {
        top: 60px;

        .header__inner {
          top: 0;
        }
      }

      .page {
        margin-top: 60px;
      }
    }
  }

  &:has(.dropdown-menu__mobile) {
    @include media-down($size-tablet) {
      .page-bottom-banner {
        display: none;
      }

      ::v-deep .header {
        top: 0 !important;
      }
    }
  }
}

main * {
  font-family: 'TildaSans', sans-serif;
}

::v-deep button {
  font-family: 'TildaSans', sans-serif;
}

::v-deep input {
  font-family: 'TildaSans', sans-serif !important;
}

// артефакты от старых страниц
::v-deep section {
  margin-bottom: 0;

  h2,
  h3 {
    margin: 0;
  }
}
</style>
